.dd {
    &__wrapper {
        position: relative;
    }
    &__header {
        border: 1px solid black;
        border-radius: 3px;
        width: 140px;
        text-align: center;
        cursor: pointer;
        padding: 5px 0px;
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            background: url("../../images/angle-arrow-down.png") center no-repeat;
            background-size: contain;
            right: 0;
            top: 55%;
            transform: translateX(-50%) translateY(-50%);
            transition: all linear 0.2s;
        }
        &.active {
            &::after {
                transform: rotate(180deg) translateX(+50%) translateY(+50%);
            }
        }
    }
    &__list {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: #fff;
        box-sizing: border-box;
        gap: 5px;
        max-height: 0px;
        overflow: hidden;
        border: 0px;
        transition: all linear 0.2s;
        opacity: 0;
        &.active {
            max-height: 100px;
            border: 1px solid black;
            border-top: 0px;
            opacity: 1;
        }
    }
    &__listItem {
        padding: 5px 0px;
        width: 100%;
        height: 100%;
        text-align: center;
        cursor: pointer;
        &.active {
            background-color: #6d6c6c;
            color: #fff;
        }
    }
}
