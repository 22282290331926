.App {
    height: 100vh;
    background-color:#fff;
}
.container {
    max-width: 1042px;
    margin: 0 auto;
    padding: 63px 10px 10px 10px;
    background-color:#fff;
    @media (max-width: $bp2) {
        padding: 20px 10px 10px 10px;
    }
}
