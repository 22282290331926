.voice-chooser {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &__buttons {
        display: flex;
        column-gap: 20px;
        @media (max-width: $bp1) {
            column-gap: 15px;
        }
    }
    &__button {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    &__title {
        margin-right: 10px;
        @media (max-width: $bp1) {
            margin-right: 7px;
        }
    }
    &__lang {
        margin-left: 10px;
        @media (max-width: $bp1) {
            margin-left: 7px;
        }
    }
}
