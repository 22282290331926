.header {
    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: $bp2) {
            justify-content: space-around;
            margin-top: 10px;
            flex-wrap: wrap;
            gap: 10px;
        }
    }
    &__top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        @media (max-width: $bp2) {
            justify-content: center;
        }
    }
}
