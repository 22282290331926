.translator {
    min-height: 452px;
    border: 1px solid #d8d8d8;
    border-radius: 30px;
    margin-top: 17px;
    display: flex;
    flex-direction: column;
    @media (max-width: $bp1) {
        min-height: 600px;
    }

    &__header {
        padding: 13px 0px 0px;
        margin: 0 auto;
        width: 100%;
        &::after {
            content: "";
            display: block;
            width: 100%;
            margin-top: 13px;
            height: 13px;
            background-color: red;
            background: url("/assets/images/Pattern.png") repeat-x;
            background-position: bottom 0px right 50%;
            border-top: 1px solid #d8d8d8;
            padding-top: 2px;
        }
    }
    &__content {
        display: grid;
        flex-grow: 1;
        position: relative;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;

        @media (max-width: $bp1) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
        }
    }
    &__box {
        padding: 18px 28px;
        box-sizing: border-box;
        position: relative;
        @media (max-width: $bp2) {
            padding: 10px 10px;
        }
        @media (max-width: $bp1) {
            flex: 0 0 50%;
        }
        &::-webkit-scrollbar {
            width: 6px; /* ширина scrollbar */
        }
        &::-webkit-scrollbar-track {
            background: #fff; /* цвет дорожки */
        }
        &::-webkit-scrollbar-thumb {
            background-color: #d8d8d8;
            border-radius: 20px;
            border-right: 2px solid #fff;
        }
        &:first-child {
            &::after {
                content: "";
                position: absolute;
                right: 0;
                height: 100%;
                bottom: 0;
                width: 1px;
                background-color: #d8d8d8;
                @media (max-width: $bp1) {
                    height: 1px;
                    width: 100%;
                }
            }
        }
    }

    &__keys {
        margin-bottom: 10px;
    }

    &__eximage {
        width: 150px;
        height: 150px;
        margin-top: 40px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @media (max-width: $bp1) {
            width: 170px;
            height: 170px;
            margin: 0 auto;
        }
    }
}
