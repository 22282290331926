body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@import "./variables.scss";
@import "./layout.scss";
@import "./header.scss";

@import "./components/voiceChooser.scss";
@import "./components/translator.scss";
@import "./components/langSwaper.scss";
@import "./components/mansiKeys.scss";
@import "./components/translationInput.scss";
@import "./components/autoComplite.scss";
@import "./components/translationOutput.scss";
@import "./components//autoComplite.scss";
@import "./components/dropdownButton.scss";
@import "./components/table.scss";
