$bp1: 576px;
$bp2: 768px;
$bp3: 1200px;

.text {
    font-family: "PT Sans";
    color: #6d6c6c;
    &--sm {
        font-size: 18px;
        @media (max-width: $bp1) {
            font-size: 16px;
        }
    }
    &--mdl {
        font-size: 22px;
        @media (max-width: $bp1) {
            font-size: 20px;
        }
    }
    &--big {
        font-size: 24px;
        @media (max-width: $bp1) {
            font-size: 22px;
        }
    }
    &--it {
        font-style: italic;
        color: #9c9c9c;
    }
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.desctop {
    @media (max-width: $bp2) {
        display: none;
    }
}

.mobile {
    @media (max-width: $bp1) {
        display: none;
    }
}

.only-mob {
    display: none;
    @media (max-width: $bp2) {
        display: block;
    }
}
