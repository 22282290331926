.table {
    &__wrapper {
        margin-top: 15px;
    }

    &__header {
        display: flex;
        align-items: center;
        & input {
            resize: none;
            padding: 5px;
            overflow: hidden;
            box-sizing: border-box;
            height: 40px;
            min-height: 40px;
            border-width: 0px;
            border: none;
            outline: none;
            box-shadow: none;
            resize: none;
            padding-bottom: 1px;
            border-bottom: 1px solid #d8d8d8;
            margin-right: 30px;
            @media (max-width: 600px) {
                margin: 0px;
            }
        }
        @media (max-width: 600px) {
            flex-direction: column;
            justify-content: center;
            gap: 20px;
        }
    }

    &__list {
        margin-top: 20px;
    }

    &__item {
        width: 100%;
        min-height: 50px;
        text-align: center;
        border: 1px solid #000;
        border-radius: 5px;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        gap: 5px;
    }
    &__translation {
        flex: 1 1 50%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    &__word {
        margin-right: 10px;
    }
}
